import React from "react"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"

// Components
import CaseStudyHero from "components/case-study-hero/"
import FeaturedImage from "./featured-image"

const Hero = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CaseStudyHero
        title={t(
          "Aligning two thought-leaders in the field to advance digital health"
        )}
        details={["HPI·MS", "USA & Germany", "2020"]}
        tags={[
          t("Branding"),
          t("Design Sprint"),
          t("Website Design & Development"),
        ]}
        backgroundColor={colors.supernova}
        color={colors.midnight}
        bulletColor={colors.indiblue}
      />
      <FeaturedImage />
    </React.Fragment>
  )
}

export default Hero

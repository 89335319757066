import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"
import Carousel from "components/case-study-carousel/"

const StyledCarousel = styled(Carousel)`
  width: 100%;
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}

  .slide {
    max-width: 800px;
  }
`

const Results = () => {
  const data = useStaticQuery(graphql`
    query {
      carouselImageOne: file(
        relativePath: { eq: "case-studies/samsung/the-results-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/samsung/the-results-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/samsung/the-results-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/samsung/the-results-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFive: file(
        relativePath: { eq: "case-studies/samsung/the-results-image-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Section>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Giving attendants of the the Samsung Developer Conference a
              beautiful and engaging experience
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              We got to know our audience: on brand, on message and on the
              cutting edge — that’s what developers want.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              Thanks to a joint effort carried out by the teams in Korea, US,
              Spain and Argentina we were able to test the site in multiple
              resolutions. This ensured that all the different versions of the
              site represented the design flawlessly.
            </Trans>
          </p>
          <br />
          <p>
            <b>
              <Trans>The result: </Trans>
            </b>{" "}
            <Trans>A meaningful event experience.</Trans>
          </p>
        </div>
      </div>
      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Img
            fluid={data.carouselImageOne.childImageSharp.fluid}
            alt="Samsung"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageTwo.childImageSharp.fluid}
            alt="Samsung"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="Samsung"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="Samsung"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFive.childImageSharp.fluid}
            alt="Samsung"
          />
        </div>
      </StyledCarousel>
    </Section>
  )
}

export default Results
import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

// Videos
import Video from "assets/videos/case-studies/samsung/samsung-video.mp4"

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
  padding: 0 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${breakpoint.small`
    margin: 80px 0;
    padding: 0;
  `}

  img {
    display: inline-block;
  }

  video {
    max-width: 100%;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  }

  .w-50 {
    width: 100%;

    @media screen and (max-width: 767px) {
      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 0;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(4) {
        order: 3;
      }
    }

    ${breakpoint.small`
      display: inline-block;
      width: 50%;

      &:nth-child(1) {
        display: inline-flex;
        align-items: flex-end;
        justify-content: flex-end;

        .gatsby-image-wrapper {
          width: 100%;
        }
      }

      &:nth-child(3) {
        
        .gatsby-image-wrapper {
          margin-left: auto;
        }
      }
    `}
  }
`

const Solution = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/samsung/the-solution-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 606, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageTwo: file(
        relativePath: { eq: "case-studies/samsung/the-solution-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 693, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageThree: file(
        relativePath: { eq: "case-studies/samsung/the-solution-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 701, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageFour: file(
        relativePath: { eq: "case-studies/samsung/the-solution-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Section>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>A website that spoke to both developers and creators</Trans>
          </h3>
          <br />
          <p className="title">
            <Trans>Enhanced Content Strategy</Trans>
          </p>
          <p>
            <Trans>
              We analyzed the event's previous site to identify issues regarding
              navigation structure and information architecture. A benchmark
              analysis was also carried out to understand how we needed to
              communicate with our target audience.
            </Trans>
          </p>
        </div>

        <ImageWrapper>
          <video autoPlay loop muted>
            <source src={Video} type="video/mp4" />
          </video>
        </ImageWrapper>

        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>User Interface</Trans>
          </p>
          <p>
            <Trans>
              Samsung is a consolidated brand and we needed to follow their
              brand guidelines when working on the design, while also allowing
              for a more innovative design to fully represent the nature of this
              international meetup. There was also additional design direction
              based on other communicational aspects of the conference that were
              being developed by AgencyEA, simultaneously.
            </Trans>
          </p>
        </div>
      </div>

      <ImageWrapper>
        <div className="w-50">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            style={{ maxWidth: "606px" }}
            alt="Samsung"
          />
        </div>
        <div className="w-50">
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            style={{ maxWidth: "693px" }}
            alt="Samsung"
          />
        </div>
        <div className="w-50">
          <Img
            fluid={data.imageThree.childImageSharp.fluid}
            style={{ maxWidth: "701px" }}
            alt="Samsung"
          />
        </div>
        <div className="w-50">
          <Img
            fluid={data.imageFour.childImageSharp.fluid}
            style={{ maxWidth: "560px" }}
            alt="Samsung"
          />
        </div>
      </ImageWrapper>
    </Section>
  )
}

export default Solution
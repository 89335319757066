import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const ImageWrapper = styled.div`
  max-width: 373px;
  display: flex;
  flex-wrap: wrap;
  margin: 32px auto 0 auto;

  ${breakpoint.small`
    max-width: 1120px;
    flex-wrap: nowrap;
    margin-top: 64px;
  `}

  > div {
    width: 100%;

    ${breakpoint.medium`
      width: calc(100% / 3);
    `}
  }
`

const Challange = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/samsung/the-challenge-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/samsung/the-challenge-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageThree: file(
        relativePath: { eq: "case-studies/samsung/the-challenge-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The challenge</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              How do you put on an event that both shapes and reflects the
              evolution of technology?
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              The Samsung Developer Conference is a meeting point for all kinds
              of talented tech leaders where they get together to learn about
              what's next in intelligent technology.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              In this event, one of the main attractions is when Samsung reveals
              their new cutting edge products and services and having the
              possibility of meeting developers from all over the world to
              generate worldwide networks.
            </Trans>
          </p>
          <br />
          <br />
          <p className="title">
            <Trans>Scalability in three stages</Trans>
          </p>
          <p>
            <Trans>
              Due to the fact that the event had three stages (pre conference,
              onsite and post conference) we had to create a scalable web with
              components and sections that could appear and disappear when
              needed, giving the attendants and potential attendees clear
              information about what was going on in real time.
            </Trans>
          </p>
        </div>
      </div>
      <ImageWrapper>
        <div>
          <Img fluid={data.imageOne.childImageSharp.fluid} alt="Samsung" />
        </div>
        <div>
          <Img fluid={data.imageTwo.childImageSharp.fluid} alt="Samsung" />
        </div>
        <div>
          <Img fluid={data.imageThree.childImageSharp.fluid} alt="Samsung" />
        </div>
      </ImageWrapper>
    </Section>
  )
}

export default Challange